<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Newsletter</strong>
              <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" style="float: right"/>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CRow>
              <CCol xs="12" xl="12">
                <NewsletterGenerator/>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import NewsletterGenerator from "@/components/NewsletterGenerator";
export default {
  name: 'NewsletterGeneratorPage',
  components: {
    NewsletterGenerator,
    DoubleBounceLoader,
  },
  data () {
    return {
      loading: false,
      saving: false
    }
  }
}
</script>
